exports.linkResolver = (doc) => {
  
  if (doc.type === 'home_page') {
    return `/`
  }

  if (doc.type === 'about_page') {
    return `/${doc.uid}/`
  }

  if (doc.type === 'contact_page') {
    return `/${doc.uid}/`
  }
  
  return '/'
}