import React from 'react'
import { linkResolver } from '../utils/link_resolver'
import { graphql } from 'gatsby'
import Footer from '../components/footer'
import Nav from '../components/nav'
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from '../components/seo'
import { PrismicRichText } from '@prismicio/react'

const NotFoundPage = ({data}) => {
  const entry = data.prismicNotFoundPage
  if (!entry) return null
	return (
    <div className="bg-grey">
      <Nav />
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <div className="w-full py-12 pb-24 bg-grey">
        <div className="w-11/12 mx-auto max-w-screen-xl">
          <div className="flex-none sm:flex justify-between gap-24">
            <div className="w-full sm:w-8/12">
              <div className="font-verdana prose prose-lg font-verdana text-black leading-normal">
                <h1>{entry.data.heading}</h1>
                <PrismicRichText field={entry.data.text.richText} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <GatsbyImage image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ?? ''} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
      </div>
      <Footer />
    </div>
	)
}

export default NotFoundPage;

export const query = graphql`
query {
  prismicNotFoundPage {
    data {
      meta_description
      page_title
      heading
      text {
        richText
      }
      image {
        alt
        gatsbyImageData
      }
    }
  }
}
`